import React from 'react'

const OrdersLoading = () => {
    const skeletonItems = Array.from({ length: 3 }, (_, index) => (
        <div className='loading-orders-item' key={index}>
            <div className='loading-orders-top'>
                <div className='block block-1'></div>
                <div className='block block-2'></div>
            </div>
            <div className='block loading-orders-title'></div>
            <div className='loading-orders-top'>
                <div className='block block-3'></div>
                <div className='block-4'>
                    <span/>
                    <span/>
                    <span/>
                </div>
            </div>
        </div>
    ));
      
  return (
    <div className='loading-orders'>
        <div>
            {skeletonItems}
        </div>
        <div className='flare'></div>
    </div>
  )
}

export default OrdersLoading