import React from 'react'
import { useFormContext } from 'react-hook-form';

const PickupItem = ({pickupAddresses, setPickupItem, pickupItem}) => {
    const {register} = useFormContext();

  return (
    <div className='delivery-card-list'>
        {pickupAddresses?.items?.map((card, index) =>
            <div className={pickupItem?.id == card.id ? 'pickup-item active' : 'pickup-item'} key={index} onClick={() => setPickupItem(card)}>
                <div className='pickup-item-title'>{card.title}</div>
                <p>{card.address}</p>
                {(card.from && card.to) ?
                    <>
                        <p>с {card.from} до {card.to}</p>
                    </>
                    :
                    <></>
                }
                <input className="delivery-card-input" type="radio" 
                    value={JSON.stringify({ pickup_id: card.id, full_address: card.title, pickup_address: card.address, location: card.location, type: 'self'})} 
                    {...register('address_id', {required: true})} required 
                />
            </div>
        )}
    </div>
  )
}

export default PickupItem