import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import UserServices from '../../services/general/userServices';
import { toast } from 'react-toastify';

const ReferalProgram = observer(() => {
    const {app} = useContext(Context);
    const navigate = useNavigate();

    const [code, setCode] = useState();

    const fetchProfile = async() =>{
        const res = await UserServices.profile.index();

        if(res.statusCode === 200){
            setCode(res.content?.referral_code)
        }else{
            toast.error(res.message)
        }
    }

    useEffect(() => {
        fetchProfile();
    }, [])

    const copyText = () => {
        navigator.clipboard.writeText(code)
        .then(() => {
            toast.success(app.localizationsItems?.code_success_copy);
        })
        .catch((err) => {
            console.error(app.localizationsItems?.code_error_copy, err);
            toast.error(app.localizationsItems?.code_error_copy);
        });
    };

  return (
    <div>
        <div className='button-back'>
            <i className='icon-chevron-left' onClick={() => navigate(`/${app.lang}/mob-user`)}></i>
            <h3>{app.localizationsItems?.invite_friend}</h3>
        </div>
        <div className="referal-box">
            <h3>{app.localizationsItems?.your_referal_code}</h3>
            <div className='text mb-3' dangerouslySetInnerHTML={{__html: app.localizationsItems?.your_referal_code_content}}></div>
            <div className='input border referal-input-icon'>
                <span>{code}</span>
                <i className='icon-content_copy' onClick={copyText}></i>
            </div>
        </div>
    </div>
  )
})

export default ReferalProgram