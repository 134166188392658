import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import AppServices from '../../services/general/appServices';

const LangCitySelectModal = observer(({show, setShow, cities}) => {
    const {app, user} = useContext(Context);

    const [loading, setLoading] = useState();
    const {handleSubmit, register, setValue} = useForm();

    useEffect(() => {
        if(localStorage.getItem('branch')){
            setValue('branch', localStorage.getItem('branch'))
        }
    }, [])

    const handleClose = () => {
        setShow(false);
    }

    const CustomSubmit = async(data) => {
        setLoading(true)
        const selectedBranch = JSON.parse(data.branch);
        app.setBranch(selectedBranch)

        const res = await AppServices.settings2(selectedBranch?.value);

        if(res.statusCode === 200){
            let settings = res.content;

            app.setSettings(settings);
        }
        setLoading(false)
        setShow(false)
    };

    const handleChange = (lang) => {
        if(app.lang !== lang.slug){
            app.setLang(lang.slug)
        }
    }

  return (
    <Modal show={show} onHide={handleClose} centered className='city-modal'>
        <div className='close' onClick={handleClose}>
            <i className='icon-close'></i>
        </div>
        <Modal.Body>
            {app.languages?.length > 1 && <div className='city-modal-languages'>
                <h3>{app.localizationsItems?.select_lang}</h3>

                    <div className='languages-items'>
                        {app.languages.map((card, index) =>
                            <div className={card.slug === app.lang ? 'languages-item active' : 'languages-item'} key={index} onClick={() => handleChange(card)}>{card.title}</div>
                        )}
                    </div>
            </div>}
            <form onSubmit={handleSubmit(CustomSubmit)} className={loading ? 'isLoading' : ''}>
                <h3>{app.localizationsItems?.select_city}</h3>
                <div className='city-modal-content'>
                    {Object.entries(app.branches)?.map(([key, value]) =>
                    <div key={key}>
                        <h4>{key}</h4>
                        {Object.entries(value).map(([key, card]) => (
                            <label className="form-check-label" key={key}>
                                <input className="form-check-input" required type="radio" 
                                    {...register('branch', {required: true})}
                                    value={JSON.stringify({ value: card.id, label: card.name, location: card.city?.value })} 
                                />
                                {card.name}
                            </label>
                        ))}
                    </div>
                )}
                </div>
                <button className='main-btn'>{app.localizationsItems?.save}</button>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default LangCitySelectModal