import React, { useContext, useEffect, useState } from 'react'
import { Accordion, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import UserServices from '../../services/general/userServices';
import useFetchItems from '../../hooks/useFetchItems';

const CartPaymentModal = observer(({show, setShow, fetchItems, setPayment}) => {
    const {app} = useContext(Context);
    
    const {getValues, register, formState: {errors}} = useForm();

    const {
        items:payments,
        loading
    } = useFetchItems(UserServices.cart.order.payments)

    const handleClose = () => {
        setShow(false);
    }

    const CustomSubmit = () => {
        const paymentTemp = getValues('payment_id') ? JSON.parse(getValues('payment_id')) : null;

        setPayment(paymentTemp)
        setShow(false)
    }

  return (
    <Modal show={show} onHide={handleClose} centered className="time-select-modal">
        <div className='close' onClick={handleClose}>
            <i className='icon-close'></i>
        </div>
        <Modal.Body>
            <h3>{app.localizationsItems?.payment}</h3>
            <Accordion className='payments-items'>
                {payments?.map((card, index) =>
                    <Accordion.Item eventKey={card.id} key={index}>
                        <Accordion.Header className={card.description ? "" : 'after-none'}>
                            <label className="form-check-label">
                                <input className="form-check-input" required type="radio" 
                                    {...register('payment_id', {required: true})}
                                    value={JSON.stringify({ payment_id: card.id, payment: card.name})}
                                />
                                {card.name}
                            </label>
                        </Accordion.Header>
                        {card.description && <Accordion.Body>
                            <div dangerouslySetInnerHTML={{__html: card.description}}></div>
                        </Accordion.Body>}
                    </Accordion.Item>
                )}
            </Accordion>

            <div className='main-btn' onClick={CustomSubmit}>{app.localizationsItems?.save}</div>
        </Modal.Body>
    </Modal>
  )
})

export default CartPaymentModal