import React, { useContext, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import DroupdownLang from '../ui/DroupdownLang'
import { Context } from '../..'

const TopNavigation = observer(({openMenu, phone, handleClose}) => {
    const {app} = useContext(Context);

  return (
    app.languages?.length > 1 &&
    <div className={openMenu ? 'top-navigation open' : 'top-navigation'}>
        <span className='close-button' onClick={handleClose}>
            <i className='icon-close'></i>
        </span>
        <div className="container">
            <div className="flex">
                <DroupdownLang/>
                <nav className='top-nav'>
                    <NavLink to={`/${app.lang}/politika-konfidentsialnosti`} onClick={handleClose}>{app.localizationsItems?.privacy_police}</NavLink>
                    <NavLink to={`/${app.lang}/publichnaya-oferta`} onClick={handleClose}>{app.localizationsItems?.public_offer}</NavLink>
                </nav>
            </div>
        </div>
    </div>
  )
})

export default TopNavigation