import React, { useContext, useEffect, useState } from 'react'
import UserServices from '../../services/general/userServices';
import { observer } from 'mobx-react-lite';
import Utils from '../../services/utils';
import { Context } from '../..';
import { toast } from 'react-toastify';

const RecomendedCard = observer(({card, openProduct}) => {
    const {app, user} = useContext(Context);
    const [inCart, setInCart] = useState();
    const [count, setCount] = useState(1);

    useEffect(() => {
        if(user.cartProducts){
            const product = user.cartProducts.find(it => it.id === card.id);

            if(product){
                setInCart(true);
                setCount(product.count)
            }else{
                setInCart(false)
            }
        }
    }, [user.cartProducts])

    /*const toCart = async () => {
        let newData = {
            uid: user.uid ? user.uid : null,
            product_id: card.id,
            count: 1,
        }
          
        const res = await UserServices.cart.addToCart(newData);

        if (res.statusCode === 200) {
            if(!user.uid){
                res.content.uid && user.setUid(res.content.uid);
            }
            user.setBasketChange(Math.random().toString(16))
            toast.success(app.localizationsItems?.product_add_cart)
        }else{
            toast.error(res.message)
        }
    }*/

    const plusCount = async () => {
        /*if(card.traits?.length >= 1){
            setShow(true);
            return
        }*/
        setCount(count+1);
        const data = {
            uid: user.uid,
            product_id: card.id,
            count: count+1
        }

        const res = await UserServices.cart.update(data);

        if(res.statusCode === 200){
            user.setBasketChange(Math.random().toString(16))
        }else{
            setCount(count)
        }
    }

    const deleteItem = async () => {
        //setLoading(true);

        const data = {
            uid: user.uid,
            product_id: card.id
        }

        const res = await UserServices.cart.remove(data);

        if(res.statusCode === 200){
            user.setBasketChange(Math.random().toString(16));
            //setLoading(false);
            setInCart(false)
            toast.success(res.message)
        }else{
            toast.error(res.message)
        }
    }

    const minusCount = async () => {
        if(count > 1){
            setCount(count-1);
            const data = {
                uid: user.uid,
                product_id: card.id,
                count: count-1
            }
    
            const res = await UserServices.cart.update(data);
    
            if(res.statusCode === 200){
                user.setBasketChange(Math.random().toString(16))
            }else{
                setCount(count)
            }
        }else if(count <= 1){
            deleteItem();
        }
    }

  return (
    <div className='product-recomended' onClick={() => openProduct(card.slug)}>
        <img src={card.image} alt="" />
        <div className='title'>{card.title}</div>
        <div className='price' onClick={() => openProduct(card.slug)}>{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency}
            <i className='icon-plus'></i>
        </div>
    </div>
  )
})

export default RecomendedCard