import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../..'
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';
import { NavLink } from 'react-router-dom';
import appstore from "../../images/appstore.png"
import googlestore from "../../images/googleplay.png"
import LoginModal from '../modals/LoginModal';
import LoginCodeModal from '../modals/LoginCodeModal';

const FooterMenu = observer(() => {
    const {app, catalog} = useContext(Context);

    const [phoneText, setPhoneText] = useState();
    const [email, setEmail] = useState();
    const [address, setAddress] = useState();
    const [appStoreLink, setAppStoreLink] = useState();
    const [googleStoreLink, setGoogleStoreLink] = useState();
    const [publicOffer, setPublicOffer] = useState();
    const [privacyPolicy, setPrivacyPolicy] = useState();

    const [show, setShow] = useState();
    const [codeShow, setCodeShow] = useState();

    const [phone, setPhone] = useState();

    const {
        items,
        loading
    } = useFetchItems(AppServices.pages)

    useEffect(() => {
        if(app.settings){
			setPhoneText(app.settings.find(it => it.type === "phone")?.value);
            setEmail(app.settings.find(it => it.type === "email")?.value);
            setAddress(app.settings.find(it => it.type === "address")?.value);
            setAppStoreLink(app.settings.find(it => it.type === "app-store-link")?.value);
            setGoogleStoreLink(app.settings.find(it => it.type === "google-play-link")?.value);
            setPublicOffer(app.settings.find(it => it.type === "public_offer")?.value);
            setPrivacyPolicy(app.settings.find(it => it.type === "privacy_policy")?.value);
        }
    }, [app.settings])

    const handleScrollToCategory = (category) => {
        var element = document.querySelector(category);
        var headerOffset = 63;
        if(element){
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }

  return (
    <div className='footer-menu'>
        <div className="container">
            <div className="footer-menu-wrapper">
                <div className='footer-nav'>
                    <h3>{app.localizationsItems?.menu}</h3>
                    <div className='menu-grid'>
                        {catalog.categories?.map((card, index) =>
                            <div key={index} className='nav-item' onClick={() => handleScrollToCategory(`#${card.slug}`)}>{card.title}</div>
                        )}
                    </div>
                </div>
                {(items?.items?.length >= 1 || publicOffer || privacyPolicy) ?
                    <div className='footer-nav'>
                        <h3>{app.localizationsItems?.sections}</h3>
                        <div>
                            {items?.items?.map((el, ind) =>
                                <NavLink to={`/${app.lang}/page/${el.slug}`} target={el.type} key={ind}>{el.title}</NavLink>
                            )}
                            {privacyPolicy && <NavLink to={`/${app.lang}/politika-konfidentsialnosti`}>{app.localizationsItems?.privacy_police}</NavLink>}
                            {publicOffer && <NavLink to={`/${app.lang}/publichnaya-oferta`}>{app.localizationsItems?.public_offer}</NavLink>}
                        </div>   
                    </div>
                    : <></>
                }
                <div className='footer-nav'>
                    <h3>{app.localizationsItems?.contacts}</h3>
                    <div className='contacts'>
                        {phoneText?.map((ph, index) =>
                            <a href={`tel:+${ph}`} key={index}>{app.localizationsItems?.tel}: {ph}</a>  
                        )}
                        {/*address?.map((ad, index) =>
                            <span key={index}>{app.localizationsItems?.address}: {ad}</span>  
                        )*/}
                        {email?.map((em, index) =>
                            <a href={`mailto:+${em}`} key={index}>{app.localizationsItems?.email}: {em}</a>  
                        )}
                        {app.localizationsItems?.contacts_info &&
                            <div className='contacts-text' dangerouslySetInnerHTML={{__html: app.localizationsItems.contacts_info}}></div>
                        }
                    </div>
                </div>
            </div>
            {/*(appStoreLink || googleStoreLink) ? 
                <div className='footer-app'>
                    <h3>{app.localizationsItems?.app_title}</h3>
                    <p>{app.localizationsItems?.app_content}</p>
                    <div className='footer-link'>
                        {appStoreLink && <a href={appStoreLink} target='_blank'>
                            <img src={appstore} alt="" />
                        </a>}
                        {googleStoreLink && <a href={googleStoreLink} target='_blank'>
                            <img src={googlestore} alt="" />
                        </a>}
                    </div>
                </div>
                :
                <></>
            */}
        </div>
        {show && <LoginModal show={show} setShow={setShow} setCodeShow={setCodeShow} setPhone={setPhone}/>}
        {codeShow && <LoginCodeModal show={codeShow} setShow={setCodeShow} phone={phone}/>}
    </div>
  )
})

export default FooterMenu