import React, { useContext, useState } from 'react'
import { Context } from '../..'
import { observer } from 'mobx-react-lite';
import LoginModal from '../modals/LoginModal';
import LoginCodeModal from '../modals/LoginCodeModal';

const ProfileLogin = observer(() => {
    const {app} = useContext(Context);

    const [show, setShow] = useState();
    const [codeShow, setCodeShow] = useState();

    const [phone, setPhone] = useState();

    /*const successCallback = () => {
        window.location.reload();
    }*/

  return (
    <>
        <div className='profile-login'>
            <h3>{app.localizationsItems?.login_profile}</h3>
            <p>{app.localizationsItems?.login_profile_content}</p>
            <button className='main-btn' onClick={() => setShow(true)}>{app.localizationsItems?.login}</button>
        </div>
        {show && <LoginModal show={show} setShow={setShow} setCodeShow={setCodeShow} setPhone={setPhone}/>}
        {codeShow && <LoginCodeModal show={codeShow} setShow={setCodeShow} phone={phone}/>}
    </>
  )
})

export default ProfileLogin