import React from 'react'

const NotificationLoading = () => {
    const skeletonItems = Array.from({ length: 6 }, (_, index) => (
        <div className='loading-notification-item' key={index}>
            <div className='block-1'></div>
        </div>
    ));
      
  return (
    <div className='loading-orders'>
        <div className='loading-address'>
            {skeletonItems}
        </div>
        <div className='flare'></div>
    </div>
  )
}

export default NotificationLoading