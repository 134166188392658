import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import useFetchItems from '../../hooks/useFetchItems';
import UserServices from '../../services/general/userServices';
import OrderCard from './OrderCard';
import OrdersLoading from './OrdersLoading';
import Pagination from './Pagination';

const ActiveOrders = observer(() => {
    const {app} = useContext(Context);

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
    } = useFetchItems(UserServices.orders.index)

  return (
    <>
        {loading ?
            <OrdersLoading/>
            :
            <div>
                {items?.items?.length >= 1 ?
                    <div>
                        {items.items.map((card, index) =>
                            <OrderCard card={card} key={index}/>
                        )}
                        <div className='custome-pagination'>
                            <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                        </div>
                    </div>
                    :
                    <div>
                        <h4>{app.localizationsItems?.no_active_orders}</h4>
                    </div>
                }
            </div>
        }
    </>
  )
})

export default ActiveOrders