import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../..'
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import UserServices from '../../services/general/userServices';
import placeholder from "../../images/placeholder.png"

const ProductCardButton = observer(({info, setShow}) => {
    const {app, user} = useContext(Context);
    const [inCart, setInCart] = useState();
    const [count, setCount] = useState(1);

    useEffect(() => {
        if(user.cartProducts){
            const product = user.cartProducts.find(it => it.id === info.id);

            if(product){
                setInCart(true);
                setCount(product.count)
            }else{
                setInCart(false)
            }
        }
    }, [user.cartProducts])

    const plusCount = async () => {
        if(info.traits?.length >= 1 || info.price_configs?.length >= 1){
            setShow(true);
            return
        }
        setCount(count+1);
        const data = {
            uid: user.uid,
            product_id: info.id,
            count: count+1
        }
        
        const res = await UserServices.cart.update(data);

        if(res.statusCode === 200){
            user.setBasketChange(Math.random().toString(16))
        }else{
            setCount(count)
        }
    }

    const minusCount = async () => {
        if(count > 1){
            setCount(count-1);
            const data = {
                uid: user.uid,
                product_id: info.id,
                count: count-1
            }
    
            const res = await UserServices.cart.update(data);
    
            if(res.statusCode === 200){
                user.setBasketChange(Math.random().toString(16))
            }else{
                setCount(count)
            }
        }else if(count <= 1){
            deleteItem();
        }
    }

    const deleteItem = async () => {
        //setLoading(true);

        const data = {
            uid: user.uid,
            product_id: info.id
        }

        const res = await UserServices.cart.remove(data);

        if(res.statusCode === 200){
            user.setBasketChange(Math.random().toString(16));
            //setLoading(false);
            setInCart(false)
            toast.success(res.message)
        }else{
            toast.error(res.message)
        }
    }

    const flyToCart = (e) => {
        const cart = window.innerWidth < 767 ? document.querySelector('#cart-mob-button') : document.querySelector('#cart-button');

        const img = document.createElement('img');
        img.src = info.image ? info.image : placeholder;
        img.className = 'fly-image';

        document.body.appendChild(img);

        const startX = e.clientX;
        const startY = e.clientY;

        img.style.left = `${startX}px`;
        img.style.top = `${startY}px`;

        const cartRect = cart.getBoundingClientRect();
        const cartX = cartRect.left + cartRect.width / 2 - img.width / 2;
        const cartY = cartRect.top + cartRect.height / 2 - img.height / 2;

        img.style.transform = `translate(${cartX - startX}px, ${cartY - startY}px)`;
        img.addEventListener('transitionend', () => {
            img.classList.add('hidden');
            setTimeout(() => {
                img.remove();
            }, 500);
        });
    }

    const toCart = async (data) => {
        if(info.traits?.length >= 1 || info.price_configs?.length >= 1){
            setShow(true);
            return
        }
        flyToCart(data)
        let newData = {
            uid: user.uid ? user.uid : null,
            product_id: info.id,
            count: 1,
        }
          
        const res = await UserServices.cart.addToCart(newData);

        if (res.statusCode === 200) {
            if(!user.uid){
                res.content.uid && user.setUid(res.content.uid);
            }
            user.setBasketChange(Math.random().toString(16))
            //toast.success(app.localizationsItems?.product_add_cart)
        }else{
            toast.error(res.message)
        }
    }

  return (
    info?.shipment?.slug === "unavailable" ?
        <button className='main-btn btn-sm btn-icon' disabled>
            {app.localizationsItems?.not_available} ⊘
        </button>
    :
    info?.shipment?.slug === "on_wait" ?
        <button className='main-btn btn-sm btn-icon' disabled>
            {app.localizationsItems?.on_wait} ⊘
        </button>
    :
    <>
        {inCart ?
            <div className='input-count input-count-mini'>
                <div className='input-btn' onClick={minusCount}>
                    <i className='icon-minus'></i>
                </div>
                <div className='value'>{count}</div>
                <div className='input-btn' onClick={plusCount}>
                    <i className='icon-plus'></i>
                </div>
            </div>
            :
            <button className='main-btn btn-sm btn-icon' onClick={toCart}>
                {app.localizationsItems?.to_cart}
            </button>
        }
    </>
  )
})

export default ProductCardButton