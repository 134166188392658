import React from 'react'
import ProfileTabs from '../../components/complex/ProfileTabs';

const Profile = () => {
  return (
    <div className='profile'>
        <div className="container">
            <ProfileTabs/>
        </div>
    </div>
  )
}

export default Profile