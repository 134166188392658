import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Context } from '../..';

const MobCategoryScrollMenu = observer(({isFixed}) => {
    const {catalog, app, user} = useContext(Context);

    const scrollContainerRef = useRef(null);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const [logo, setLogo] = useState();

    useEffect(() => {
        if(app.settings.length >= 1){
            setLogo(app.settings.find(it => it.type === "logo")?.value);
        }
    }, [app.settings])

    const [activeSection, setActiveSection] = useState(null);
    
    const handleMouseDown = (e) => {
        setIsMouseDown(true);
        setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
        setScrollLeft(scrollContainerRef.current.scrollLeft);
    };
  
    const handleMouseLeave = () => {
        setIsMouseDown(false);
    };
  
    const handleMouseUp = () => {
        setIsMouseDown(false);
    };
  
    const handleMouseMove = (e) => {
        if (!isMouseDown) return;
        e.preventDefault();
        const x = e.pageX - scrollContainerRef.current.offsetLeft;
        const walk = (x - startX) * 3;
        scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    }; 

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
        
            const sections = document.querySelectorAll('.category-item');

            sections.forEach((section) => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.clientHeight;
                if ((scrollPosition >= (sectionTop + 280)) && (scrollPosition < (sectionTop + sectionHeight + sectionHeight/2))) {
                    let menuItem = document.querySelector(`#${section.id}-mob`);
                    scrollContainerRef.current.scrollTo({
                        left: menuItem?.offsetLeft,
                        behavior: "smooth"
                    });
                    setActiveSection(section.id);
                }
            });
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScrollToCategory = (category) => {
        var element = document.querySelector(category);
        var headerOffset = 63;
        if(element){
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }

  return (
    <div className={isFixed ? 'sticky' : ''}>
        <img src={logo} className='logo-img' id='logo-img'/>
        <div className='categories-list'
            ref={scrollContainerRef}
            onMouseDown={e => handleMouseDown(e)}
            onMouseLeave={e => handleMouseLeave(e)}
            onMouseUp={e => handleMouseUp(e)}
            onMouseMove={e => handleMouseMove(e)}
        >
            {user.favorites?.length >= 1 && <div className={activeSection === `favorites` ? 'category-card active' : 'category-card'}
                    onClick={() => handleScrollToCategory(`#favorites`)}
                    id={`favorites-mob`}
                >
                <i className='icon-like'></i>
                <span>{app.localizationsItems?.wishlist}</span>
            </div>}
            {catalog.categories?.map((card, index) =>
                <div className={activeSection === `${card.slug}` ? 'category-card active' : 'category-card'} key={index}
                    onClick={() => handleScrollToCategory(`#${card.slug}`)}
                    id={`${card.slug}-mob`}
                >
                    <span>{card.title}</span>
                </div>
            )}
        </div>
    </div>
  )
})

export default MobCategoryScrollMenu