import React from 'react'

const HeaderLoading = () => {
  return (
    <div className='header-loading'>
        <div className='header-loading-top'>
            <div className="container">
                <div className='header-loading-top-wrapper'>
                    <div className='loading-logo'></div>
                    <div className='loading-list'>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
        <div className='header-loading-top'>
            <div className="container">
                <div className='header-loading-top-wrapper header-loading-center'>
                    <div className='loading-logo-center'></div>
                    <div className='loading-list-big'>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
        <div className='header-loading-top'>
            <div className="container">
                <div className='header-loading-top-wrapper header-loading-bottom'>
                    <div className='loading-bottom-left'>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className='loading-button'></div>
                </div>
            </div>
        </div>
        <div className='flare'></div>
    </div>
  )
}

export default HeaderLoading