import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { Context } from '../..';
import useFetchItems from '../../hooks/useFetchItems';
import UserServices from '../../services/general/userServices';
import Pagination from '../simple/Pagination';

const Bonuses = observer(() => {
    const {app} = useContext(Context);

    const navigate = useNavigate();

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
    } = useFetchItems(UserServices.profile.balanceHistory)

  return (
    <div className='profile-dashboard'>
        <div className='button-back'>
            <i className='icon-chevron-left' onClick={() => navigate(`/${app.lang}/mob-user`)}></i>
            <h3>{app.localizationsItems?.bonus_history}</h3>
        </div>
        {loading ?
            <div className='loader-wrapper'>
                <span className='loader'/>
            </div>
            :
            <div>
                {items?.items?.length >= 1 ?
                    <div>
                        {items.items.map((card, index) =>
                            <div className='bonus-card' key={index}>
                                <div>
                                    <div className='title'>{card.transaction}</div>
                                    <div className='date'>{card.created_at}</div>
                                </div>
                                <div className={card.value <= 0 ? 'bonus-state danger' : 'bonus-state'}>
                                    {card.value} ₸
                                </div>
                            </div>
                        )}
                        <div className='custome-pagination'>
                            <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                        </div>
                    </div>
                :
                    <div><h5>{app.localizationsItems?.empty_data}</h5></div>
                }
            </div>
        }
    </div>
  )
})

export default Bonuses