import React, { useContext, useEffect, useState } from 'react'
import { NavLink, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Context } from '../..';
import PorfileBonus from '../simple/PorfileBonus';
import ProfileLogin from './ProfileLogin';
import { observer } from 'mobx-react-lite';
import AuthServices from '../../services/general/authServices';

const ProfileMobTabs = observer(() => {
    const {app, user} = useContext(Context);
    const [token, setToken] = useState(localStorage.getItem('token'));

    useEffect(() => {
        setToken(user.token)
    }, [user.tokenChange])
    
    const location = useLocation();

    const navigate = useNavigate();

    const logOut = async () => {
        const res = await AuthServices.logOut();
        
        if (res.statusCode === 200) {
            user.setUser({})
            user.removeToken()
            user.setBasketChange(Math.random().toString(16));
            navigate(`/${app.lang}`);
        }
    }

  return (
    <div>
        {token ?
            <>
                <h3 className='profile-h3'>{user.user.name  ? user.user.name : app.localizationsItems?.my_data}</h3>
                <div className='profile-phone mb-3'>{user.user?.phone}</div>
                <PorfileBonus/>
                <div className="tab-links">
                    <div className={(location.pathname === `/${app.lang}/user` || location.pathname === `/${app.lang}/user/dashboard`) ? 'tab-links-item active' : 'tab-links-item'}>
                        <NavLink to={`/${app.lang}/user/dashboard`}>
                            <i className='icon-user-thin'></i>
                            {app.localizationsItems?.my_data}
                        </NavLink>
                    </div>

                    <div className={((location.pathname === `/${app.lang}/user/orders`) || (location.pathname === `/${app.lang}/user/orders-history`)) ? 'tab-links-item active' : 'tab-links-item'} >
                        <NavLink to={`/${app.lang}/user/orders`}>
                            <i className='icon-bill'></i>
                            {app.localizationsItems?.my_orders}
                        </NavLink>
                    </div>
                    
                    <div className={location.pathname === `/${app.lang}/user/addresses` ? 'tab-links-item active' : 'tab-links-item'}>
                        <NavLink to={`/${app.lang}/user/addresses`}>
                            <i className='icon-pin'></i>
                            {app.localizationsItems?.all_addresses}
                        </NavLink>
                    </div>
                    
                    <div className={location.pathname === `/${app.lang}/user/notifications` ? 'tab-links-item active' : 'tab-links-item'}>
                        <NavLink to={`/${app.lang}/user/notifications`}>
                            <i className='icon-notification'></i>
                            {app.localizationsItems?.notifications}
                        </NavLink>
                    </div>
                    
                    {/*<div className={location.pathname === `/${app.lang}/user/invite_friend` ? 'tab-links-item active' : 'tab-links-item'}>
                        <NavLink to={`/${app.lang}/user/invite_friend`}>
                            <i className='icon-team'></i>
                            {app.localizationsItems?.invite_friend}
                        </NavLink>
                    </div>*/}
                    
                    <div className={location.pathname === `/${app.lang}/user/reviews` ? 'tab-links-item active' : 'tab-links-item'}>
                        <NavLink to={`/${app.lang}/user/reviews`}>
                            <i className='icon-chat'></i>
                            {app.localizationsItems?.reviews}
                        </NavLink>
                    </div>
                    
                    <div className={location.pathname === `/${app.lang}/user/support` ? 'tab-links-item active' : 'tab-links-item'}>
                        <NavLink to={`/${app.lang}/user/support`}>
                            <i className='icon-chat-1'></i>
                            {app.localizationsItems?.support}
                        </NavLink>
                    </div>
                    
                    <div className={location.pathname === `/${app.lang}/user/about_us` ? 'tab-links-item active' : 'tab-links-item'}>
                        <NavLink to={`/${app.lang}/user/about_us`}>
                            <i className='icon-user-thin'></i>
                            {app.localizationsItems?.about_us}
                        </NavLink>
                    </div>
                    
                    {/*<div className={location.pathname === `/${app.lang}/user/faq` ? 'tab-links-item active' : 'tab-links-item'}>
                        <NavLink to={`/${app.lang}/user/faq`}>
                            <i className='icon-faq'></i>
                            {app.localizationsItems?.faq}
                        </NavLink>
                    </div>*/}
                </div>
                <div className='main-btn' onClick={logOut}>{app.localizationsItems?.logout}</div>
            </>
            :
            <>
                <ProfileLogin/>
                <div className="tab-links">
                    <div className={location.pathname === `/${app.lang}/user/support` ? 'tab-links-item active' : 'tab-links-item'}>
                        <NavLink to={`/${app.lang}/user/support`}>
                            <i className='icon-chat-1'></i>
                            {app.localizationsItems?.support}
                        </NavLink>
                    </div>
                    
                    <div className={location.pathname === `/${app.lang}/user/about_us` ? 'tab-links-item active' : 'tab-links-item'}>
                        <NavLink to={`/${app.lang}/user/about_us`}>
                            <i className='icon-user-thin'></i>
                            {app.localizationsItems?.about_us}
                        </NavLink>
                    </div>
                    
                    {/*<div className={location.pathname === `/${app.lang}/user/faq` ? 'tab-links-item active' : 'tab-links-item'}>
                        <NavLink to={`/${app.lang}/user/faq`}>
                            <i className='icon-faq'></i>
                            {app.localizationsItems?.faq}
                        </NavLink>
                    </div>*/}
                </div>
            </>
        }
    </div>
  )
})

export default ProfileMobTabs