import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import DeliveryModal from '../modals/DeliveryModal';

const DeliveryStock = observer(({deliveryPromocode}) => {
    const {user} = useContext(Context);

    const [show, setShow] = useState();

    const [remainder, setRemainder] = useState();
    const [isFull, setIsFull] = useState();
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if(user.cartProducts){
            let temp = 0;
            for(let i = 0; i < user.cartProducts.length; i++){
                temp += Number(user.cartProducts[i]?.price) * Number(user.cartProducts[i].count);
            }
            const minSumm = Number(deliveryPromocode.min_price);

            if(minSumm <= temp){
                setProgress(100);
                setRemainder(null);
                setIsFull(true)
            }else{
                let tempProgress = (temp*100)/minSumm;
                setProgress(tempProgress);
                setRemainder(Number(minSumm-temp));
                setIsFull(false)
            }
        }
    }, [user.cartProducts])

  return (
    <div className={isFull ? 'delivery-stock full' : 'delivery-stock'}>
        <div className='delivery-stock-title' onClick={() => setShow(!show)}>
            <div className='output' style={{ clipPath: `polygon(0% 0%, ${progress}% 0, ${progress}% 100%, 0% 100%)` }}></div>
            <div className='output-inner'></div>
            <div className='inner'><i className='icon-delivery'></i></div>
        </div>

        {show && <DeliveryModal show={show} setShow={setShow} remainder={remainder} isFull={isFull} progress={progress} deliveryPromocode={deliveryPromocode}/>}
    </div>
  )
})

export default DeliveryStock