import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import AuthServices from '../../services/general/authServices';

const LoginModal = observer(({
    show,
    setShow, 
    setCodeShow, 
    setPhone,
    setRegistrationModal,
    setForgotPasswordModal
}) => {
    const {app, user} = useContext(Context);

    const [loading, setLoading] = useState();
    const [authType, setAuthType] = useState();

    useEffect(() => {
        if(app.settings){
            setAuthType(app.settings.find(it => it.type === "auth_type")?.value);
        }
    }, [app.settings])
    
    const handleClose = () => setShow(false);

    const {handleSubmit, register, getValues, control, formState: {errors}} = useForm();

    const CustomSubmit = async () => {
        setLoading(true);
        let newData = {}

        if(authType === "email"){
            newData = {
                email: getValues('email'),
                password: getValues('password')
            }
        }else{
            newData = {
                phone: getValues('phone')
            }
        }


        const res = await AuthServices.logIn(newData);

        if (res.statusCode === 200) {
            if(authType === "email"){
                user.setToken(res.content.token);
                user.setBasketChange(Math.random().toString(16));
                user.setTokenChange(Math.random().toString(16));
                setShow(false);
            }else{
                setCodeShow(true);
                setPhone(newData.phone);
                setShow(false);
            }
        }else{
            toast.error(res.message);
        }
        setLoading(false);
    }

  return (
        <Modal show={show} onHide={handleClose} centered className='login-modal-container'>
            <div className='close' onClick={handleClose}>
                <i className='icon-close'></i>
            </div>
            <Modal.Body>
                <form className='login-modal' onSubmit={handleSubmit(CustomSubmit)}>
                    {loading &&
                        <div className='loader-wrapper-absolute'> <span className="loader"></span> </div>
                    }
                    <h2>{app.localizationsItems?.entry}</h2>
                    <p>{app.localizationsItems?.login_content}</p>

                    {authType === "email" ?
                        <>
                            <div className='form-label'>
                                <label>{app.localizationsItems?.email}*</label>
                                <input type="email" className='input' placeholder={app.localizationsItems?.email} {...register('email')} required/>
                            </div>
                            <div className='form-label'>
                                <label>{app.localizationsItems?.password}*</label>
                                <input type="password" className='input' placeholder={app.localizationsItems?.password} {...register('password')} required/>
                            </div>
                            <div className='text-right' onClick={() => {
                                setForgotPasswordModal(true);
                                setShow(false);
                            }}>Забыли пароль?</div>
                            <div className='main-btn mb-15' onClick={CustomSubmit}>{app.localizationsItems?.login}</div>
                            <div className='text-center'>У вас нет аккаунта?</div>
                            <div className='text-center text-accent' onClick={() => {
                                setRegistrationModal(true);
                                setShow(false);
                            }}>{app.localizationsItems?.registration}</div>
                        </>
                    :
                        <>
                            <div className='form-label'>
                                <label>{app.localizationsItems?.phone}*</label>
                                <Controller defaultValue="" name="phone" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <ReactInputMask onChange={onChange} value={value}
                                            mask="+7(999)999-99-99" maskChar="_">
                                            {
                                                inputProps => (
                                                    <input type="tel" className="input" placeholder={app.localizationsItems?.phone} {...inputProps}/>
                                                )
                                            }
                                        </ReactInputMask>
                                    )}
                                    rules={{pattern: {
                                        value: /(?:\+|\d)[\d\-() ]{9,}\d/g
                                    }}}
                                />
                            </div>
                            <div className='main-btn' onClick={CustomSubmit}>{app.localizationsItems?.get_code}</div>
                        </>
                    }
                </form>
            </Modal.Body>
        </Modal>
  )
})

export default LoginModal