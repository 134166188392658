import http from "./http-common"
import {getData, postData} from "../handlers";

export default class CatalogServices {
    static categories = {
        index: () => {
            return getData(http, `/app/catalog/categories`);
        },
        indexIds: (queryString) => {
            let params = ''
            if(queryString){
                queryString.forEach(element => {
                    params += `ids[]=${element}&`
                });
            }
            return getData(http, `/app/catalog/categories/without-subcategories${params ? `?${params}` : ""}`);
        },
        view: (slug, queryString) => {
            return getData(http, `/app/catalog/categories/${slug}${queryString ? `?${queryString}` : ""}`);
        }
    }

    static products = {
        index: (queryString) => {
            let temp = null;

            try{
                temp = JSON.parse(localStorage.getItem('branch'));
            }catch(e){
                temp = null
            }

            let str = temp?.value ? `branch_id=${temp.value}&${queryString}` : queryString
            return getData(http, `/app/catalog/products${str ? `?${str}` : ""}`);
        },

        suggest: (slug, queryString) => {
            return getData(http, `/app/catalog/products/${slug}/suggest${queryString ? `?${queryString}` : ""}`);
        },

        latestProducts: () => {
            return getData(http, `/app/catalog/products`);
        },

        view: (slug, queryString) => {
            return getData(http, `/app/catalog/products/${slug}${queryString ? `?${queryString}` : ""}`);
        }
    }
}