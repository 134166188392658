import React, { useContext, useEffect, useState } from 'react'
import Slider from 'react-slick';
import { Context } from '../..';
import CatalogServices from '../../services/general/catalogServices';
import RecomendedCard from '../simple/RecomendedCard';

const ProductRecommendeds = ({slug, openProduct}) => {
    const {user, app} = useContext(Context);
    
    const settings = {
        arrows: true,
        infinite: false,
        slidesToShow: 3,
    }

    const [items, setItems] = useState();

    const fetchProducts = async () => {
        let str = app.branch?.value && `branch_id=${app.branch?.value}`

        const res = await CatalogServices.products.suggest(slug, str);
  
        if(res.statusCode === 200){
            res.content.items && setItems(res.content.items);
        }
    }

    useEffect(() => {
        fetchProducts()
    }, [])

  return (
    items?.length >= 1 &&
    <div className='new-recommended-slider'>
        <h5>{app.localizationsItems?.something_else}</h5>
        <Slider {...settings}>
            {items.map((card, index) =>
                <RecomendedCard card={card} key={index} openProduct={openProduct}/>
            )}
        </Slider>
    </div>
  )
}

export default ProductRecommendeds