import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom';
import { Context } from '../..';
import HistoryOrders from '../simple/HistoryOrders';

const OrderHistory = observer(() => {
    const {app} = useContext(Context);
  return (
    <div className='profile-dashboard'>
        <h4 className='mb-30'>{app.localizationsItems?.my_orders}</h4>
        <div className='order-link-wrapper'>
            <NavLink to={`/${app.lang}/user/orders`} className='order-link'>{app.localizationsItems?.active_orders}</NavLink>
            <div className='order-link active'>{app.localizationsItems?.history}</div>
        </div>
        <HistoryOrders/>
    </div>
  )
})

export default OrderHistory