import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import AuthServices from '../../services/general/authServices';
import { toast } from 'react-toastify';

const LoginCodeModal = observer(({show, setShow, phone, successCallback}) => {
    const {app, user} = useContext(Context);

    const [loading, setLoading] = useState();

    const handleClose = () => setShow(false);

    const {handleSubmit, getValues, register} = useForm();

    const onSubmit = async () => {
        setLoading(true)
        const newData = {
            phone:phone,
            code: getValues('code')
        }

        const res = await AuthServices.authWithPhone(newData);

        if (res.statusCode === 200) {
            setShow(false);
            toast.success(res.message);
            user.setUser(res.content);
            user.setToken(res.content.token);
            user.setBasketChange(Math.random().toString(16));
            user.setTokenChange(Math.random().toString(16));
            successCallback && successCallback();
        }else{
            toast.error(res.message);
        }
        setLoading(false)
    }

  return (
    <Modal show={show} onHide={handleClose} centered className='login-modal-container'>
        <div className='close' onClick={handleClose}>
            <i className='icon-close'></i>
        </div>
        <Modal.Body>
            <form className='login-modal' onSubmit={handleSubmit(onSubmit)}>
                {loading &&
                    <div className='loader-wrapper-absolute'> <span className="loader"></span> </div>
                }
                <h2>{app.localizationsItems?.entry}</h2>
                <div className='form-label'>
                    <label>{app.localizationsItems?.code}</label>
                    <input type="text" className="input" {...register('code')} required placeholder={app.localizationsItems?.code}/>
                </div>
                <div className='main-btn' onClick={onSubmit}>{app.localizationsItems?.login}</div>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default LoginCodeModal