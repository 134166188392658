import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { Context } from '../..'
import ReviewsServices from '../../services/general/reviewsServices';
import { toast } from 'react-toastify';
import DeleteModal from '../modals/DeleteModal';

const ProfileReviewCard = observer(({
    card,
    fetchItems
}) => {
    const {app} = useContext(Context);
    const [selectedRating, setSelectedRating] = useState(card.points);

    const [show, setShow] = useState();

    const stars = [1, 2, 3, 4, 5];

    const deleteItem = async () => {
        const res = await ReviewsServices.delete(card.id);

        if(res.statusCode === 200){
            toast.success(res.message)
            setShow(false);
            fetchItems();
        }else{
            toast.error(res.message);
        }
    }

  return (
    <div className='review-card'>
        <div className='d-flex justify-content-end' onClick={() => setShow(true)}>
            <i className='icon-remove'></i>
        </div>
        <div className='review-card-flex'>
            <div className='r-date'>{card.created_at}</div>
            <ul className="rating">
                {stars.map((star, index) => 
                    <li key={index}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" 
                            fill={selectedRating >= star ? '#ffb321' : 'none'}
                            stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" 
                            className="feather feather-star">
                            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                    </li>
                )}
            </ul>
        </div>
        {card.product && <div className='review-card-product'>
            <img src={card.product.preview_image} alt="" />
            <div>
                <h4>{card.product.title}</h4>
            </div>
        </div>}
        {card.attachments?.length >= 1 ?
            <div className='review-card-attachments'>
                <img src="" alt="" />
                <img src="" alt="" />
                <img src="" alt="" />
            </div>
            :
            <></>
        }
        <p>{card.text}</p>
        {show && 
            <DeleteModal
                show={show}
                setShow={setShow}
                deleteItem={deleteItem}
            />
        }
    </div>
  )
})

export default ProfileReviewCard