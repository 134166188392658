import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { Context } from '../..'
import { NavLink } from 'react-router-dom';

const ErrorPage = observer(() => {
    const {app} = useContext(Context);

  return (
    <div className='container'>
        <div className='error-page'>
            <h5>404</h5>
            <h1>{app.localizationsItems?.error_title}</h1>
            <p>{app.localizationsItems?.error_content}</p>
            <NavLink to={`/${app.lang}`} className='main-btn'>{app.localizationsItems?.error_button}</NavLink>
        </div>
    </div>
  )
})

export default ErrorPage