import React from 'react'

const FooterLoading = () => {
  return (
    <div className='footer-loading'>
        <div className="container">
            <div className="footer-loading-wrapper">
                <div>
                    <div className='loading-1 loading-title-1'></div>
                    <div className='loading-grid'>
                        <div className='loading-1'></div>
                        <div className='loading-1'></div>
                        <div className='loading-1'></div>
                        <div className='loading-1'></div>
                        <div className='loading-1'></div>
                        <div className='loading-1'></div>
                        <div className='loading-1'></div>
                        <div className='loading-1'></div>
                        <div className='loading-1'></div>
                        <div className='loading-1'></div>
                    </div>
                </div>
                <div>
                    <div className='loading-1 loading-title'></div>
                    <div className='loading-1 loading-md'></div>
                    <div className='loading-1 loading-mn'></div>
                    <div className='loading-1 loading-md'></div>
                </div>
                <div>
                    <div className='loading-1 loading-title-big'></div>
                    <div className='loading-1 loading-bg'></div>
                    <div className='loading-1 loading-bg'></div>
                    <div className='loading-1 loading-bg-last'></div>
                </div>
            </div>
            <div className="footer-loading-bottom">
                <div>
                    <div className='loading-1 loading-big'></div>
                    <div className='loading-1 loading-long'></div>
                    <div className='loading-flex'>
                        <div className='loading-1'></div>
                        <div className='loading-1'></div>
                    </div>
                    <div className='loading-1 loading-last'></div>
                </div>
                <div>
                    <div className='loading-1 loading-last'></div>
                    <div className='loading-mini'>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div className='flare'></div>
        </div>
    </div>
  )
}

export default FooterLoading