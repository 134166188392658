import React, { useContext, useRef, useState } from 'react'
import { Context } from '../..';
import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import useFetchItems from '../../hooks/useFetchItems';
import UserServices from '../../services/general/userServices';
import { toast } from 'react-toastify';
import Utils from '../../services/utils';
import { useDetectOutsideClick } from '../../hooks/useDetectOutsideClick.hook';
import AppServices from '../../services/general/appServices';

const DeliveryUserCards = observer(({address, addressValue, CustomSubmit, setAddressValue, deliveryZones, setLocation}) => {
    const {app} = useContext(Context);
    const [activePoint, setActivePoint] = useState();

    const [activeAddAddress, setActiveAddress] = useState();
    
    const [loading, setLoading] = useState();

    const {register, getValues} = useFormContext();
    
    const dropdown = useRef();
    const [isActive, setIsActive] = useDetectOutsideClick(dropdown, false);

    const [typingTimeout, setTypingTimeout] = useState(0);

    const [availableAddresses, setAvailableAddresses] = useState();

    const {
        items,
        fetchItems
    } = useFetchItems(UserServices.addresses.index)

    const createAddress = async () => {
        let result = null;

        deliveryZones.forEach(zone => {
            if (Utils.isMarkerInsidePolygon([address?.lat, address?.lng], zone.zones)) {
                result = zone.title;
            }
        });

        if(deliveryZones?.length > 1 && !result){
            toast.error(app.localizationsItems?.address_no_zone)
            return
        }

        const newData = {
            json_address: {
                entrance: getValues('entrance') ? getValues('entrance') : null,
                floor: getValues('floor') ? getValues('floor') : null,
                apartment: getValues('apartment') ? getValues('apartment') : null,
                code: getValues('code') ? getValues('code') : null,
                address: addressValue,
                lat: address?.lat,
                lng: address?.lng
            },
            full_address: addressValue
        }

        const res = await UserServices.addresses.store(newData);

        if (res.statusCode === 200) {
            fetchItems();
            toast.success(app.localizationsItems?.success_add_address);
            setActiveAddress(false);
        }else{
            toast.error(res.message)
        }
    }

    const deleteAddress = async (e, id) => {
        e.stopPropagation();
        setLoading(true);

        const res = await UserServices.addresses.delete(id);

        if (res.statusCode === 200) {
            fetchItems();
        }else{
            toast.error(res.message)
        }

        setLoading(false);
    }

    const handleChange = (e) => {
        setIsActive(true);
        setAddressValue(e.target.value);

        clearTimeout(typingTimeout);

        setTypingTimeout(setTimeout(async () => {
            const res = await AppServices.suggest(e.target.value);

            if (res.statusCode === 200) {
                setAvailableAddresses(res.content?.items);
            }
        }, 700));
    }

    const handleSelectLocation = async (el) => {
        setIsActive(false);
        setAddressValue(el.title);
        const res = await AppServices.search(el.title);

        if(res.statusCode === 200){
            setLocation(res.content.geo)
        }else{
            toast.error(res.message)
        }
    }

  return (
    <div className='delivery-wrapper'>
        <h3 className='mob-none'>{app.localizationsItems?.select_delivery_point}</h3>
        <div className='delivery-card-list'>
            {items?.items?.map((card, index) =>
                <div className={activePoint == card.id ? 'delivery-card-list-item active' : 'delivery-card-list-item'} key={index}>
                    {card.full_address}, 
                    <i className='icon-trash' onClick={e => deleteAddress(e, card.id)}></i>
                    <input className="delivery-card-input" type="radio" value={JSON.stringify({ pickup_id: card.id, full_address: card.full_address, json_address: card.json_address})} 
                    {...register('address_id', {required: true})} required onClick={() => setActivePoint(card.id)}/>
                </div>
            )}
            {loading &&
                <div className='loader-wrapper-absolute'> <span className="loader"></span> </div>
            }
        </div>
        <div className='main-btn btn-outline' onClick={() => setActiveAddress(true)}>{app.localizationsItems?.attach_address}</div>
        <div onClick={CustomSubmit} className='main-btn mr-0'>{app.localizationsItems?.save}</div>

        {activeAddAddress && 
            <div className='delivery-wrapper-content'>
                <h3>{app.localizationsItems?.new_address}</h3>
                <div className='search-values-container'>
                    <input 
                        value={addressValue} 
                        onChange={e => handleChange(e)} 
                        placeholder={app.localizationsItems?.address} 
                        className="input input-center"
                    ></input>
                    {isActive && <div className='search-values-items' ref={dropdown}>
                        {availableAddresses?.map((el, index) =>
                            <div className='search-values-item' key={index} onClick={e => handleSelectLocation(el)}>
                                <div>{el.title}</div>
                                <div className='search-values-item-sub'>{el.subtitle}</div>
                            </div>
                        )}
                    </div>}
                </div>
                <div className="inputs-grid">
                    <input type="text" className="input" {...register('entrance')} placeholder={app.localizationsItems?.entrance}/>
                    <input type="text" className="input" {...register('floor')} placeholder={app.localizationsItems?.floor}/>
                    <input type="text" className="input" {...register('apartment')} placeholder={app.localizationsItems?.apartment}/>
                    <input type="text" className="input" {...register('code')} placeholder={app.localizationsItems?.code_domofon}/>
                </div>
                <div className='main-btn btn-gray' onClick={() => setActiveAddress(false)}>{app.localizationsItems?.cancel}</div>
                <div onClick={createAddress} className='main-btn'>{app.localizationsItems?.save}</div>
            </div>
        }
    </div>
  )
})

export default DeliveryUserCards