import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../..'
import { NavLink } from 'react-router-dom';
import Utils from '../../services/utils';
import BonusModal from '../modals/BonusModal';
import UserServices from '../../services/general/userServices';
import { toast } from 'react-toastify';
import LoyaltyModal from '../modals/LoyaltyModal';

const PorfileBonus = observer(({isModal, setShow}) => {
    const {app, user} = useContext(Context);
    const [bonusShow, setBonusShow] = useState();

    const [info, setInfo] = useState();

    const [procentWidth, setProcentWight] = useState(0);
    const [loaltyModal, setLoaltyModal] = useState();

    const fetchProfile = async () => {
        const res = await UserServices.profile.index();

        if(res.statusCode === 200){
            setInfo(res.content)
        }else{
            toast.error(res.messsage)
        }
    }

    useEffect(() => {
        fetchProfile()
    }, [])

    useEffect(() => {
        if(info?.loyalty?.next){
            let next = info.loyalty.next;
            let width = (Number(next.current)*100)/Number(next.from);
            width >= 100 ? setProcentWight(100) : setProcentWight(width);
        }
    }, [info])

  return (
    <>
        {info?.loyalty ?
            <div className='profile-bonus profile-loyalty'>
                <div className='flex profile-bonus-top'>
                    <h4>{info?.loyalty.title}</h4>
                    <div className='profile-info' onClick={() => setLoaltyModal(true)}>
                        <i className='icon-info'></i>
                    </div>
                </div>
                <div className='flex profile-bonus-middle'>
                    <div className='bonus'>
                        <i className='icon-coin'></i>
                        {Utils.numberWithSpaces(user?.user?.balance)}
                    </div>
                </div>
                <div className='flex profile-bonus-bottom'>
                    <div className='d-flex'>
                        <h3>
                            {Utils.numberWithSpaces(info.loyalty.value)} 
                            {info.loyalty.value_type === "value" ? app.localizationsItems?.currency : '%'} 
                        </h3>
                        {info.loyalty.type === "bonus" ? app.localizationsItems?.your_cashback : app.localizationsItems?.your_discount}
                    </div>
                    <div className='gray'>
                        {isModal ?
                            user.token && <NavLink to={`/${app.lang}/user/bonuses`} onClick={() => setShow(false)}>
                                {app.localizationsItems?.history}
                            </NavLink>
                            :
                            <NavLink to={`/${app.lang}/user/bonuses`}>
                                {app.localizationsItems?.history}
                            </NavLink>
                        }
                    </div>
                </div>
                {info.loyalty.next &&
                    <div className='client-level-bottom'>
                        <h3 className='mb-0'>
                            {info.loyalty.next.type === "bonus" ? app.localizationsItems?.upgrade_level : app.localizationsItems?.upgrade_discount} 
                            <span className='ms-1'>{info.loyalty.next.value}{info.loyalty.next.value_type === "value" ? 'тг' : '%'}</span>
                        </h3>
                        <div className='d-flex align-items-end'>{app.localizationsItems?.order_more}
                            <h3 className='fw-600 ms-1 mb-0'>{Utils.numberWithSpaces(info.loyalty.next.need)} тг</h3>
                        </div>
                        <div className='client-level-scroll'>
                            <div className='client-level-scroll-content' style={{width: `${procentWidth}%`}}></div>
                        </div>
                    </div>
                }
                {loaltyModal && <LoyaltyModal show={loaltyModal} setShow={setLoaltyModal} info={info?.loyalty}/>}
            </div>
            :
            <div className='profile-bonus pd-24'>
                <div className='flex profile-bonus-top'>
                    <div>{app.localizationsItems?.bonuses}</div>
                    <div className='profile-info' onClick={() => setBonusShow(true)}>
                        <i className='icon-info'></i>
                    </div>
                </div>
                <div className='flex profile-bonus-middle'>
                    <div className='bonus'>
                        <i className='icon-coin'></i>
                        {Utils.numberWithSpaces(user?.user?.balance)}
                    </div>
                </div>
                <div className='flex profile-bonus-bottom'>
                    {isModal ?
                        user.token && <NavLink to={`/${app.lang}/user/bonuses`} onClick={() => setShow(false)}>
                            {app.localizationsItems?.history}
                        </NavLink>
                        :
                        <NavLink to={`/${app.lang}/user/bonuses`}>
                            {app.localizationsItems?.history}
                        </NavLink>
                    }
                    {/*<div className='profile-qcode'>
                        <i className='icon-qr-code'></i>
                        QR-код
                    </div>*/}
                </div>
                {bonusShow && <BonusModal show={bonusShow} setShow={setBonusShow}/>}
            </div>
        }
    </>
  )
})

export default PorfileBonus