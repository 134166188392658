import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import Utils from '../../services/utils';
import { useLocation, useNavigate } from 'react-router-dom';

const MobileFixMenu = observer(({setShow, total}) => {
    const {app, user} = useContext(Context);

    const navigate = useNavigate();
    const location = useLocation();

    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
    };

    const handleActiveItem = (name) => {
        app.setActiveMenuItem(name);
        if(name === "menu"){
            location.pathname === `/${app.lang}` ? scrollToTop() : navigate(`/${app.lang}`)
        }else if(name === "cart"){
            setShow(true)
        }else if(name === "profile"){
            navigate(`/${app.lang}/mob-user`)
        }
    }

  return (
    <div className='mobile-fix-menu'>
        <div className={app.activeMenuItem === "menu" ? 'mobile-fix-item active' : 'mobile-fix-item'} onClick={() => handleActiveItem('menu')}>
            <i className='icon-mall'></i>
            <div className='mobile-fix-title'>{app.localizationsItems?.menu}</div>
        </div>
        <div className={app.activeMenuItem === "cart" ? 'mobile-fix-item active' : 'mobile-fix-item'} onClick={() => handleActiveItem('cart')} id='cart-mob-button'>
            <i className='icon-shop-bar'></i>
            <span className='cart-count'>{user.cartProducts?.length ? user?.cartProducts?.length : 0}</span>
            <div className='mobile-fix-title'>{total ? <>{Utils.numberWithSpaces(total)} {app.localizationsItems?.currency}</> : app.localizationsItems?.cart}</div>
        </div>
        <div className={app.activeMenuItem === "profile" ? 'mobile-fix-item active' : 'mobile-fix-item'} onClick={() => handleActiveItem('profile')}>
            <i className='icon-mini-user'></i>
            <div className='mobile-fix-title'>{app.localizationsItems?.menu_profile}</div>
        </div>
    </div>
  )
})

export default MobileFixMenu