import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react'
import NotificationLoading from '../simple/NotificationLoading';
import Pagination from '../simple/Pagination';
import { useNavigate } from 'react-router-dom';
import { Context } from '../..';
import useFetchItems from '../../hooks/useFetchItems';
import ProfileReviewCard from '../simple/ProfileReviewCard';
import ReviewsServices from '../../services/general/reviewsServices';

const Reviews = observer(() => {
    const {app} = useContext(Context);

    const navigate = useNavigate();

    const {
        items,
        loading,
        pageCount,
        params,
        fetchItems,
        handlePageClick,
    } = useFetchItems(ReviewsServices.index)

  return (
    <div className='profile-dashboard'>
        <div className='button-back'>
            <i className='icon-chevron-left' onClick={() => navigate(`/${app.lang}/mob-user`)}></i>
            <h3>{app.localizationsItems?.reviews}</h3>
        </div>
        {loading ?
            <NotificationLoading/>
            :
            <div>
                {items?.items?.map((card, index) =>
                    <ProfileReviewCard
                        card={card}
                        fetchItems={fetchItems}
                        key={card.id}
                    />
                )}
                <div className='custome-pagination'>
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </div>
            </div>
        }
    </div>
  )
})

export default Reviews