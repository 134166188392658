import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import MapComponent from '../complex/MapComponent';

const UserAddAddressModal = observer(({show, setShow, setNewAddress}) => {
    const {app} = useContext(Context)
    
    const [address, setAddress] = useState();

    const handleClose = () => setShow(false);

    const {handleSubmit, getValues, register, formState: {errors}} = useForm();

    const CustomSubmit = async () => {
        const newData = {
            json_address: {
                entrance: getValues('entrance') ? getValues('entrance') : null,
                floor: getValues('floor') ? getValues('floor') : null,
                apartment: getValues('apartment') ? getValues('apartment') : null,
                code: getValues('code') ? getValues('code') : null,
                lat: address?.lat,
                lng: address?.lng
            },
            full_address: address?.address
        }

        setNewAddress(newData);
        setShow(false)
    };

  return (
    <Modal show={show} onHide={handleClose} centered className='address-modal'>
        <div className='close' onClick={handleClose}>
            <i className='icon-close'></i>
        </div>
        <Modal.Body>
            <div className='address-modal-wrapper'>
                <div className='address-modal-container'>
                    <h2>{app.localizationsItems?.new_address}</h2>
                    <div>
                        <div className='form-label'>
                            <label>{app.localizationsItems?.address}</label>
                            <div type="text" className="input input-center">
                                {address?.address}
                            </div>
                        </div> 
                        <div className="row">
                            <div className="col-6">
                                <div className='form-label'>
                                    <label>{app.localizationsItems?.entrance}</label>
                                    <input type="text" className="input" {...register('entrance')} placeholder={app.localizationsItems?.entrance}/>
                                </div> 
                            </div>
                            <div className="col-6">
                                <div className='form-label'>
                                    <label>{app.localizationsItems?.floor}</label>
                                    <input type="text" className="input" {...register('floor')} placeholder={app.localizationsItems?.floor}/>
                                </div> 
                            </div>
                            <div className="col-6">
                                <div className='form-label'>
                                    <label>{app.localizationsItems?.apartment}</label>
                                    <input type="text" className="input" {...register('apartment')} placeholder={app.localizationsItems?.apartment}/>
                                </div> 
                            </div>
                            <div className="col-6">
                                <div className='form-label'>
                                    <label>{app.localizationsItems?.code_domofon}</label>
                                    <input type="text" className="input" {...register('code')} placeholder={app.localizationsItems?.code_domofon}/>
                                </div> 
                            </div>
                        </div>
                        <div className='main-btn' onClick={CustomSubmit}>{app.localizationsItems?.save}</div> 
                    </div>
                </div>
                <div className='map-container'>
                    <MapComponent setAddress={setAddress}/>
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default UserAddAddressModal