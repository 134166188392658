import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import UserServices from '../../services/general/userServices';
import { toast } from 'react-toastify';

const ClearBasketModal = observer(({show, setShow}) => {
    const handleClose = () => setShow(false);

    const {app, user} = useContext(Context);

    const handleDelete = async () => {
        const data = {
            uid: user.uid
        }
        const res = await UserServices.cart.clear(data);

        if(res.statusCode === 200){
            user.setBasketChange(Math.random().toString(16));
            toast.success(res.message)
            setShow(false)
        }else{
            toast.error(res.message)
        }
    };

  return (
    <Modal show={show} onHide={handleClose} centered className='delete-modal'>
        <div className='close' onClick={handleClose}>
            <i className='icon-close'></i>
        </div>
        <Modal.Body>
            <div className="delete-profile-modal">
                <h3>{app.localizationsItems?.clear_basket_basket}</h3>
                <div className="row">
                    <div className="col-6">
                        <button className='main-btn btn-gray'>{app.localizationsItems?.cancel}</button>  
                    </div>
                    <div className="col-6">
                        <button className='main-btn' onClick={handleDelete}>{app.localizationsItems?.clear}</button>  
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default ClearBasketModal