import React from 'react';
import ReactPaginate from "react-paginate";

const Pagination = ({pageCount, params, loading, handlePageClick}) => {
    return (
        pageCount > 1 &&
        <ReactPaginate
            previousLabel={<i className="icon-chevron-left"/>}
            nextLabel={<i className="icon-chevron-right"/>}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            disableInitialCallback={true}
            onPageChange={handlePageClick}
            containerClassName={`pagination ${loading ? 'isLoading' : ''}`}
            activeClassName={'active'}
            forcePage={params.get('page') ? params.get('page') - 1 : 0}
        />
    );
};

export default Pagination;