import http from "./http-common"
import {deleteData, getData, postData} from "../handlers";

export default class ReviewsServices {
    static index = (queryString) => {
        return getData(http, `/app/catalog/productReview${queryString ? `?${queryString}` : ""}`);
    }

    static view = (slug, queryString) => {
        return getData(http, `/app/catalog/productReview/${slug}${queryString ? `${queryString}` : ""}`);
    }

    static store = (data) => {
        return postData(http, `/app/catalog/productReview/store`, data);
    }

    static reply = (id, data) => {
        return postData(http, `/app/catalog/productReview/${id}/reply`, data);
    }

    static delete = (id) => {
        return deleteData(http, `/app/catalog/productReview/${id}`);
    }
}