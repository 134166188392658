import React, { useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import ReactInputMask from 'react-input-mask';

const UserInfo = observer(() => {
    const {app} = useContext(Context);
    const {register, control, formState: {errors}} = useFormContext();
    
  return (
    <div className='box'>
        <h4>{app.localizationsItems?.user_data} <span>*</span></h4>
        <div>
            <div className='form-label'>
                <label>{app.localizationsItems?.name}</label>
                <input type="text" className="input" required {...register('name')} placeholder={app.localizationsItems?.name}/>
            </div> 
            <div className='form-label'>
                <label>{app.localizationsItems?.phone}</label>
                <Controller defaultValue="" name="phone" control={control}
                        render={({field: {onChange, value}}) => (
                            <ReactInputMask onChange={onChange} value={value}
                                mask="+7(999)999-99-99" maskChar="_">
                                {
                                    inputProps => (
                                        <input type="tel" className="input" placeholder={app.localizationsItems?.phone} {...inputProps}/>
                                    )
                                }
                            </ReactInputMask>
                        )}
                        rules={{pattern: {
                            value: /(?:\+|\d)[\d\-() ]{9,}\d/g
                        }}}
                    />
            </div> 
            <div className='form-label'>
                <label>{app.localizationsItems?.email}</label>
                <input type="email" className="input" required {...register('email')} placeholder={app.localizationsItems?.email}/>
            </div> 
        </div>
    </div>
  )
})

export default UserInfo