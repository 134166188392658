import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import AuthServices from '../../services/general/authServices';

const UpdatePasswordModal = observer(({
    show,
    setShow, 
    email,
    code
}) => {
    const {app} = useContext(Context);

    const [loading, setLoading] = useState();
    
    const handleClose = () => setShow(false);

    const {handleSubmit, register, getValues, control, formState: {errors}} = useForm();

    const CustomSubmit = async () => {
        setLoading(true);
        let newData = {
            email: email,
            code: code,
            password: getValues('password'),
            password_confirmation: getValues('password_confirmation')
        }

        const res = await AuthServices.updatePassword(newData);

        if (res.statusCode === 200) {
            setShow(false);
            toast.success(res.message);
        }else{
            toast.error(res.message);
        }
        setLoading(false);
    }

  return (
        <Modal show={show} onHide={handleClose} centered className='login-modal-container'>
            <div className='close' onClick={handleClose}>
                <i className='icon-close'></i>
            </div>
            <Modal.Body>
                <form className='login-modal' onSubmit={handleSubmit(CustomSubmit)}>
                    {loading &&
                        <div className='loader-wrapper-absolute'> <span className="loader"></span> </div>
                    }
                    <h2>{app.localizationsItems?.recovery_password}</h2>
                    <p>{app.localizationsItems?.forgot_password_content}</p>
                    <div className='form-label'>
                        <label>{app.localizationsItems?.password}*</label>
                        <input type="password" className='input' placeholder={app.localizationsItems?.email} {...register('password')} required/>
                    </div>
                    <div className='form-label'>
                        <label>{app.localizationsItems?.password_confirmation}*</label>
                        <input type="password" className='input' placeholder={app.localizationsItems?.email} {...register('password_confirmation')} required/>
                    </div>
                    <div className='main-btn mb-15' onClick={CustomSubmit}>{app.localizationsItems?.update_password}</div>
                </form>
            </Modal.Body>
        </Modal>
  )
})

export default UpdatePasswordModal