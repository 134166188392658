import React, { useContext, useState } from 'react'
import DeliverySelectModal from '../modals/DeliverySelectModal'
import TimeSelectModal from '../modals/TimeSelectModal';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const CartDelivery = observer(({setDate, date, description, setDesctiption}) => {
    const {user, app} = useContext(Context);

    const [show, setShow] = useState();
    const [timeModalShow, setTimeModalShow] = useState();

  return (
    <div className='cart-delivery'>
        <div className='cart-delivery-item'>
            <div className='cart-delivery-item-title'>{app.localizationsItems?.delivery_address}</div>
            <div className='cart-delivery-item-content' onClick={() => setShow(true)}>
                <div className='flex-delivery'>
                    <span>{user?.address?.full_address ? user?.address?.full_address : app.localizationsItems?.delivery_select} </span>
                    {user?.address?.pickup_address && <span className='flex-delivery-mini'>{user?.address?.pickup_address}</span>}
                </div>
                <i className='icon-chevron-right'></i>
            </div>
        </div>
        <div className='cart-delivery-item'>
            <div className='cart-delivery-item-title'>{app.localizationsItems?.time_delivery}</div>
            <div className='cart-delivery-item-content' onClick={() => setTimeModalShow(true)}>
                {date?.day} {date?.time ? `- ${date?.time}` : ''}
                <i className='icon-chevron-right'></i>
            </div>
        </div>
        <div className='cart-delivery-item'>
            <div className='cart-delivery-item-title'>{app.localizationsItems?.order_comment}</div>
            <textarea className='textarea' value={description} onChange={e => setDesctiption(e.target.value)} placeholder={app.localizationsItems?.your_comment}></textarea>
        </div>
        {show && <DeliverySelectModal show={show} setShow={setShow}/>}
        {timeModalShow && <TimeSelectModal show={timeModalShow} setShow={setTimeModalShow} setDate={setDate}/>}
    </div>
  )
})

export default CartDelivery