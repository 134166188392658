import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Context } from '../..'
import { observer } from 'mobx-react-lite';
import Utils from '../../services/utils';

const OrderCard = observer(({card}) => {
    const {app} = useContext(Context);

  return (
    <NavLink to={`/${app.lang}/user/orders/${card.id}`}>
        <div className='order-card'>
            <div className='order-card-top'>
                <div className='order-status' style={{background: card.status?.color}}>
                    {card.status?.name}
                </div>
                {/*<div className='qr-code'>
                    <i className='icon-qr-code'></i>
  </div>*/}
            </div>
            <div className='order-wrapper'>
                <div>
                    <div className='order-text'>{card.address?.title ? card.address?.title : card.address?.full_address}</div>
                    <p>{app.localizationsItems?.order}: №{card.number}</p>
                    <div className='date'>{card.created_at}</div>
                    <div className='order-price'>{Utils.numberWithSpaces(card.total_price)} {app.localizationsItems?.currency}</div>
                </div>
                    {card.products &&
                        <div className='order-products'>
                            {card.products.slice(0, 2).map((el, index) =>
                                <img src={el.image} alt="" key={index}/>
                            )}
                            {card.products.length >= 3 && <div className='order-card-box'>+{Number(card.products.length) - 2}</div>}
                        </div>
                    }
            </div>
        </div>
    </NavLink>
  )
})

export default OrderCard