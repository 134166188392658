import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'
import img from "../../images/placeholder.png"

const PresentProductCard = observer(({card}) => {
    const {app} = useContext(Context);

    const [loading, setLoading] = useState();

  return (
    <div className='cart-product'>
        {loading &&
            <div className='loader-wrapper-absolute'> <span className="loader"></span> </div>
        }
        <div className='cart-product-wrapper'>
            <img src={card.preview_image ? card.preview_image : img} alt={card.title} title={card.title}
                width={60}
                height={60}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
            />
            <div className='product-cart-content'>
                <h5>{card.title}</h5>
                <p>{card.subtitle}</p>
                <div className='cart-product-price'>
                    <div className='cart-product-present'>
                        <i className='icon-present'></i>
                        {app.localizationsItems?.for_present}
                    </div>
                </div>
                <div className='elems-flex'>
                    {card.bonus && 
                        <span className='product-bonus'>
                            {card.bonus.number}{card.bonus.type == 1 ? '%' : '' } Б
                        </span>
                    }
                    {card.state && 
                        <span className='product-bonus' style={{background: `${card.state.subtitle}`}}>
                            {card.state.name}
                        </span>
                    }
                </div>
            </div>
        </div>
        {card.fields?.length >= 1 ?
            <div className='cart-product-bottom'>
                {card.fields?.map((field, index) =>
                    <p key={index}>{field.name}: {field.value};</p>
                )}
            </div>
            :
            <></>
        }
    </div>
  )
})

export default PresentProductCard