import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../..'
import DeliverySelectModal from '../modals/DeliverySelectModal';
import SelectCityModal from '../modals/SelectCityModal';
import AppServices from '../../services/general/appServices';

const DeliveryItemSet = observer(() => {
    const {user, app} = useContext(Context);

    const [show, setShow] = useState();

    const [openModal, setOpenModal] = useState();

    const [branch, setBranch] = useState();
    const [branches, setBranches] =useState();

    const fetchBranches = async () => {
        const res = await AppServices.branches();
        
        if(res.statusCode === 200){
            setBranches(res.content.items);
            app.setBranches(res.content.items);

            if(res.content.items?.length <= 0){
                localStorage.removeItem('branch');
            }
        }else{
            localStorage.removeItem('branch');
        }
    }

    useEffect(() => {
        fetchBranches();
    }, [])

    useEffect(() => {
        if(branches && typeof(branches) == "object"){
            let localTemp = localStorage.getItem('branch');
            try{
                let temp = JSON.parse(localTemp);
                let tempOpenModal = true;
                for (const city in branches) {
                    const branchesList = branches[city];
                    if (branchesList?.find(it => it.name == temp?.label)) {
                        app.setBranch(temp);
                        setBranch(temp);
                        tempOpenModal = false;
                        break;
                    } else if (branches[city]) {
                        const tempBranch = { 
                            value: branches[city][0]?.id, 
                            label: branches[city][0]?.name, 
                            location: branches[city][0]?.city?.value 
                        };
                        app.setBranch(tempBranch);
                        setBranch(tempBranch);
                    }
                }
                if(tempOpenModal){
                    setOpenModal(true)
                }
            }catch(e){
                if(Object.values(branches)[0]){
                    let tempBranch = Object.values(branches)[0][0];
                    if(tempBranch){
                        let modifyBranch = { value: tempBranch?.id, label: tempBranch?.name, location: tempBranch?.city?.value }
                        modifyBranch && app.setBranch(modifyBranch);
                        modifyBranch && setBranch(modifyBranch);
                        setOpenModal(true)
                    }
                }
            }
        }
    }, [branches])

  return (
    <>
        <div className='top-bar-address'>
            <div className='delivery-top'>{app.localizationsItems?.delivery_by} <b onClick={() => setOpenModal(true)}>{JSON.parse(localStorage.getItem('branch'))?.label}</b></div>
            <div className='location' onClick={() =>setShow(true)}>
                <i className='icon-map-pin'></i>
                <p>
                    {user?.address?.full_address ? 
                        user?.address?.full_address 
                        : 
                        app.localizationsItems?.delivery_select
                    }
                </p>
            </div>
        </div>
        {show && <DeliverySelectModal show={show} setShow={setShow} />}
        {openModal && 
            <SelectCityModal 
                show={openModal} 
                setShow={setOpenModal} 
                setBranch={setBranch}
                branches={branches}
            />}
    </>
  )
})

export default DeliveryItemSet