import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../..'
import MainSlider from '../../components/complex/MainSlider';
import { observer } from 'mobx-react-lite';
import Products from '../../components/complex/Products';
import PopularProducts from '../../components/complex/PopularProducts';
import StoriesComponent from '../../components/complex/StoriesComponent';
import AppAdvertisingBanner from '../../components/complex/AppAdvertisingBanner';

const MainPage = observer(() => {
    const navigate = useNavigate();
    const {app} = useContext(Context);
    const{lang: lang2} = useParams();

    const [promoBigImage, setPromoBigImage] = useState();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}`);
            }
        }
    }, [app.lang])

    useEffect(() => {
        if(app.settings){
            setPromoBigImage(app.settings.find(it => it.type === "promo_big_image")?.value);
        }
    }, [app.settings])

  return (
    <>
        {promoBigImage ?
            <div className='mb-50'>
                <MainSlider/>
            </div>
            :
            <div className='mb-50'>
                <StoriesComponent/>
            </div>
        }
        <div className='mb-50'>
            <PopularProducts/>
        </div>
        <div className='mb-50'>
            <Products/>
        </div>
        <AppAdvertisingBanner/>
    </>
  )
})

export default MainPage