import { observer } from 'mobx-react-lite';
import React, { useContext} from 'react'
import { Context } from '../..';
import { NavLink } from 'react-router-dom';
import UserIcon from './UserIcon';
import DeliveryItemSet from './DeliveryItemSet';
import LangCitySelect from './LangCitySelect';

const TopBar = observer(({openMenu, handleOpen, handleClose, phone, logo}) => {
    const {app} = useContext(Context);

  return (
    <div className='top-bar'>
        <div className='container'>
            <div className='flex mobile-menu'> 
                <div className='flex'>
                    <NavLink to={`/${app.lang}`}>
                        {logo &&
                            <img src={logo.value} 
                                className='logo'
                                alt={logo.name} 
                                title={logo.name}
                                width={115}
                                height={42}
                            />
                        }
                    </NavLink>
                    <LangCitySelect/>
                    <DeliveryItemSet/>
                </div>
                <div className='top-bar-wrapper'>
                    <UserIcon handleClose={handleClose}/>
                </div>
            </div>
        </div>
    </div>
  )
})

export default TopBar