import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import UserServices from '../../services/general/userServices';
import { toast } from 'react-toastify';

const ActivateCode = observer(() => {
    const {app, user} = useContext(Context);
    const navigate = useNavigate();

    const [friendCode, setFriendCode] = useState('');

    const handleSubmit = async() => {
        const res = await UserServices.referral.index(friendCode);

        if(res.statusCode === 200){
            toast.success(res.message);
            user.setTokenChange(Math.random().toString(16));
        }else{
            toast.error(res.message)
        }
    }

  return (
    <div>
        <div className='button-back'>
            <i className='icon-chevron-left' onClick={() => navigate(`/${app.lang}/mob-user`)}></i>
            <h3>{app.localizationsItems?.activate_code}</h3>
        </div>
        <div className="referal-box">
            <div className='text mb-3' dangerouslySetInnerHTML={{__html: app.localizationsItems?.referal_code_description}}></div>
            <div className='form-label'>
                <label>{app.localizationsItems?.referal_code}</label>
                <input value={friendCode} onChange={e => setFriendCode(e.target.value)} type="text" className="input border" placeholder={app.localizationsItems?.referal_code}/>
            </div> 
            <button className='main-btn wd-100' onClick={handleSubmit}>{app.localizationsItems?.confirm}</button>
        </div>
    </div>
  )
})

export default ActivateCode