import React, { useEffect, useState  } from 'react'
import { Modal } from 'react-bootstrap'
import 'stories-react/dist/index.css';
import img from "../../images/placeholder.png"
import Stories from 'react-insta-stories';

const StoriesModal = ({show, setShow, stories, currentStory, setVisited}) => {
    const handleClose = () => setShow(false);

    const [items, setItems] = useState();

    const Story2 = (it) => {
        useEffect(() => {
            let names = localStorage.getItem("stories_opened") ? JSON.parse(localStorage.getItem("stories_opened")) : [];
            if(!names.find(el => el === it.id)){
                names.push(it.id);
            }
            setVisited(names)
            localStorage.setItem('stories_opened', JSON.stringify(names));
            
        }, [])
        return (
          <div className='story-text'>
            <img src={it.detail_image ? it.detail_image : img} alt="" />
          </div>
        );
    };

    useEffect(() => {
        if(stories){
            const storiesTemp = stories.map(it => {
                const storyTemp = {
                    content:() => Story2(it),
                }
                return storyTemp
            })

            setItems(storiesTemp);
        }
    }, [stories])

    const storyContainerStyles = {
        borderRadius: "16px"
    }
  return (
    items &&
    <Modal show={show} onHide={handleClose} centered className='stories-modal'>
        <div className='close' onClick={handleClose}>
            <i className='icon-close'></i>
        </div>
        <Modal.Body>
            <div className="stories-modal-wrapper">
                <Stories
                    preloadCount={3}
                    stories={items}
                    loop
                    currentIndex={currentStory}
                    keyboardNavigation
                    width={'100%'}
                    height={'100%'}
                    storyContainerStyles={storyContainerStyles}
                />
            </div>
        </Modal.Body>
    </Modal>
  )
}

export default StoriesModal