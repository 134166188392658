import React, { useContext } from 'react'
import { Context } from '../..';
import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react-lite';

const OrderComment = observer(() => {
    const {app} = useContext(Context);
    const {register, formState: {errors}} = useFormContext();

  return (
    <div className='box'>
        <h4>{app.localizationsItems?.message}</h4>
        <div>
            <div className='form-label'>
                <textarea type="text" className="textarea" {...register('description')} placeholder={app.localizationsItems?.message}/>
            </div> 
        </div>
    </div>
  )
})

export default OrderComment