import React, { useContext } from 'react'
import { Accordion } from 'react-bootstrap'
import useFetchItems from '../../hooks/useFetchItems'
import UserServices from '../../services/general/userServices'
import { useFormContext } from 'react-hook-form'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'

const PaymentOption = observer(() => {
    const {app} = useContext(Context);

    const {register, formState: {errors}} = useFormContext();

    const {
        items:payments,
        loading
    } = useFetchItems(UserServices.cart.order.payments)

  return (
    <div className='box'>
        <h4>{app.localizationsItems?.payment_variant} <span>*</span></h4>
        <Accordion className='delivery-items payments'>
            {payments?.map((card, index) =>
                <Accordion.Item eventKey={card.id} key={index}>
                    <Accordion.Header className={card.description ? "" : 'after-none'}>
                        <label className="form-check-label">
                            <input className="form-check-input" required value={card.id} type="radio" 
                                {...register('payment_id', {required: true})}
                            />
                            {card.name}
                        </label>
                    </Accordion.Header>
                    {card.description && <Accordion.Body>
                        <div dangerouslySetInnerHTML={{__html: card.description}}></div>
                    </Accordion.Body>}
                </Accordion.Item>
            )}
        </Accordion>
    </div>
  )
})

export default PaymentOption